export default {
  'Read Random': 'Đọc ngẫu nhiên',
  'Group Facebook': 'Nhóm Facebook',
  'Follow us': 'Theo dõi chúng tôi',
  'Dark Mode': 'Chế độ tối',
  'Light Mode': 'Chế độ sáng',
  'Site Description':
    'Comicaholic là một website đọc truyện tranh online miễn phí với nhiều thể loại truyện tranh khác nhau. Truyện được cập nhật hàng ngày để đảm bảo không ai bỏ lỡ chương mới nhất về truyện yêu thích của họ. Nếu bạn thích trang web, vui lòng giúp lưu trang web, chia sẽ giúp chúng mình nhé. Cảm ơn bạn!',
  Home: 'Trang chủ',
  Category: 'Thể loại',
  Search: 'Tìm kiếm',
  'Search manga': 'Tìm kiếm',
  Member: 'Thành viên',
  'No results found': 'Không tìm thấy kết quả',
  'View all results': 'Xem tất cả',
  'Share Comicaholic': 'Chia sẻ Comicaholic',
  'to your friends': 'đến mọi người',
  Trending: 'Xu hướng',
  Recommended: 'Khuyến khích',
  Chapter: 'Chương',
  Volume: 'Volume',
  Today: 'Ngày',
  Week: 'Tuần',
  Month: 'Tháng',
  'Latest Updates': 'Cập nhật mới nhất',
  Completed: 'Hoàn thành',
  'Show Comments': 'Hiện nhận xét',
  'Newest Comments': 'Nhận xét mới nhất',
  'Top Comments': 'Nhận xét hàng đầu',
  'Recommend Comics': 'Truyện đề xuất',
  'Continue Reading': 'Truyện đang đọc',
  'hours ago': 'giờ trước',
  Read: 'Đọc',
  Type: 'Loại',
  Status: 'Trạng thái',
  Author: 'Tác giả',
  Views: 'Lượt xem',
  voted: 'đánh giá',
  'What do you think about this manga': 'Bạn nghĩ gì về truyện này',
  Boring: 'Nhàm chán',
  Great: 'Tuyệt quá',
  Amazing: 'Kinh ngạc',
  'List Chapter': 'Danh sách chương',
  Genres: 'Thể loại',
  'Latest Updated': 'Cập nhật gần nhất',
  'New Release': 'Mới',
  'Most Viewed': 'Xem nhiều nhất',
  Completed: 'Hoàn thành',
  'Number of Chapters': 'Chương số',
  'You May Also Like': 'Bạn cũng có thể thích',
  "Author's Other Manga": 'Những truyện khác của tác giả',
  Link: 'Đường dẫn',
  'You are reading': 'Bạn đang đọc',
  Comments: 'Nhận xét',
  Settings: 'Cài đặt',
  'Manga Detail': 'Thông tin truyện',
  'Reading Mode': 'Chế độ đọc',
  Select: 'Chọn',
  Vertical: 'Dọc',
  Horizontal: 'Ngang',
  'Reading Direction': 'Hướng đọc',
  Quality: 'Chất lượng',
  High: 'Cao',
  Medium: 'Bình thường',
  Low: 'Thấp',
  'Font Select': 'Phông chữ',
  'Font Size': 'Cỡ chữ',
  'Line Spacing': 'Khoảng cách dòng',
  Background: 'Màu nền',
  Black: 'Đen',
  White: 'Trắng',
  Close: 'Đóng',
  'Sort by': 'Sắp xếp theo',
  'Show spoil': 'Hiện spoil',
  Reply: 'Trả lời',
  More: 'Thêm',
  'You must be': 'Bạn phải',
  Login: 'Đăng nhập',
  'to post a comment': 'để đăng nhận xét',
  'View more': 'Xem thêm',
  Comment: 'Nhận xét',
  'Prev Chapter': 'Chương trước',
  'Next Chapter': 'Chương tiếp theo',
  'Vote now': 'Bình chọn ngay',
  Loading: 'Đang tải',
  Filter: 'Lọc',
  All: 'Tất cả',
  Type: 'Loại',
  Comic: 'Truyện tranh',
  Novel: 'Truyện chữ',
  Status: 'Trạng thái',
  Finished: 'Đã xong',
  Publishing: 'Công bố',
  'On Hiatus': 'Bị gián đoạn',
  Discontinued: 'Ngừng sản xuất',
  'Not yet published': 'Chưa công bố',
  'Rating Type': 'Loại đánh giá',
  'G - All Ages': 'G - All Ages',
  'PG - Children': 'PG - Children',
  Score: 'Điểm',
  Appalling: 'Kinh khủng',
  Horrible: 'Tệ hại',
  'Very Bad': 'Rất tệ',
  Bad: 'Tệ',
  Average: 'Trung bình',
  Fine: 'Ổn',
  Good: 'Tốt',
  Great: 'Tuyệt quá',
  Masterpiece: 'Kiệt tác',
  Language: 'Ngôn ngữ',
  English: 'Tiếng Anh',
  Japanese: 'Tiếng Nhật',
  Vietnamese: 'Tiếng Việt',
  'Start Date': 'Ngày bắt đầu',
  Year: 'Năm',
  Month: 'Tháng',
  Day: 'Ngày',
  Sort: 'Sắp xếp',
  Default: 'Mặc định',
  'Latest Updated': 'Cập nhật gần nhất',
  'Name A-Z': 'Tên A-Z',
  'Release Date': 'Ngày phát hành',
  'Most Viewed': 'Xem nhiều nhất',
  'Search results for': 'Kết quả cho',
  'Read Now': 'Đọc ngay',
  Info: 'Xem thông tin',
  'Locked Chapter': 'Chương bị khoá',
  'Unlock chapter to read comic. pls': 'Vui lòng mở khoá chương',
  Password: 'Mật khẩu',
  'Enter Password': 'Nhập mật khẩu',
  Confirm: 'Xác nhận',
  Cancel: 'Huỷ',
  Unlock: 'Mở khoá',
  'Buy KNB': 'Nạp KNB',
  Tutorial: 'Hướng dẫn',
  'Create Code': 'Tạo mã mở khoá',
  'Create Error Report': 'Tạo báo cáo lỗi',
  Title: 'Tiêu đề',
  'Enter title': 'Nhập tiêu đề',
  Details: 'Chi tiết',
  'Enter details': 'Nhập chi tiết',
  Create: 'Tạo',
  'New Comics': 'Truyện mới',
  'In process': 'Đang tiến hành',
  Finished: 'Đã hoàn thành',
  Pausing: 'Tạm ngưng',
  Cancelled: 'Hủy bỏ',
  'Translate team': 'Nhóm dịch truyện',
  Comics: 'Truyện của nhóm',
  Paused: 'Tạm ngưng',
  'Terms of service': 'Điều khoản và dịch vụ',
  DMCA: 'DMCA',
  Contact: 'Liên hệ',
  'Terms and Conditions': 'Các điều khoản và điều kiện',
  Terms: 'Điều Kiện',
  'Modifications and typographical and writing errors': 'Sửa Đổi và lỗi in, lỗi viết',
  'Amendment of website terms of use': 'Sửa đổi điều khoản sử dụng trang web',
  'Your privacy': 'Quyền riêng tư của bạn',
  'Management Law': 'Luật quản lý',
  'Favorite Comic': 'Truyện yêu thích',
  Notifications: 'Thông báo',
  Profile: 'Hồ sơ',
  Logout: 'Đăng xuất',
  'You are on the first chapter': 'Bạn đang ở chương đầu tiên',
  'You are on the last chapter': 'Bạn đang ở chương cuối cùng',
  'Error Report': 'Báo cáo lỗi',
  terms_content:
    '<p>Bằng cách truy cập Trang web này từ <a target="_blank" href="https://comicaholic.com"> https://comicaholic.com </a>, bạn đồng ý bị ràng buộc bởi những Điều khoản và Điều kiện Sử dụng Trang web và đồng ý rằng bạn chịu trách nhiệm về thỏa thuận với bất kỳ luật địa phương hiện hành nào. Nếu bạn không đồng ý với bất kỳ điều khoản nào trong số này, bạn bị cấm truy cập trang web này. Các tài liệu trong Trang web này có thể đã đã được bảo vệ bởi bản quyền và luật thương hiệu. </p>',
  modifications_typographical_writing_errors_content:
    'Các tài liệu xuất hiện trên Trang web của Hội Mê Truyện có thể bao gồm các lỗi kỹ thuật, đánh máy hoặc nhiếp ảnh. Hội Mê Truyện sẽ không hứa rằng bất kỳ tài liệu nào trong Trang web này là chính xác, đầy đủ hoặc hiện tại. Hội Mê Truyện có thể thay đổi các tài liệu có trên Trang web của mình bất cứ lúc nào mà không cần thông báo. Hội Mê Truyện không đưa ra bất kỳ cam kết nào để cập nhật các tài liệu.',
  amendment_website_terms_use_content:
    'Hội Mê Truyện có thể sửa đổi các Điều khoản sử dụng này cho Trang web của mình bất cứ lúc nào mà không cần thông báo trước. Bằng cách sử dụng Trang web này, bạn đồng ý bị ràng buộc bởi phiên bản hiện tại của các Điều khoản và Điều kiện sử dụng này.',
  privacy_content: 'Vui lòng đọc chính sách bảo mật của chúng tôi.',
  management_law_content:
    'Bất kỳ khiếu nại nào liên quan đến Trang web của Hội Mê Truyện sẽ được điều chỉnh bởi luật của BQ mà không liên quan đến xung đột của các quy định pháp luật.',
  'DMCA takedown request requirements': 'Yêu cầu gỡ xuống theo DMCA',
  'Contact Us': 'Liên hệ với chúng tôi',
  Updating: 'Đang cập nhật',
  'Profile Manager': 'Quản lý hồ sơ',
  'Email address': 'Địa chỉ email',
  'Display name': 'Tên',
  'Change password': 'Thay đổi mật khẩu',
  'Current password': 'Mật khẩu hiện tại',
  'New password': 'Mật khẩu mới',
  'Confirm new password': 'Xác nhận mật khẩu mới',
  'Save': 'Lưu',
  'Change Avatar': 'Thay đổi ảnh',
  Ascending: 'Tăng dần',
  Descending: 'Giảm dần',
};
