export default {
  'Read Random': 'Read Random',
  'Group Facebook': 'Group Facebook',
  'Follow us': 'Follow us',
  'Dark Mode': 'Dark Mode',
  'Light Mode': 'Light Mode',
  'Site Description':
    'MangaReader is a Free website to download and read manga online. We have a big library of over 600,000 manga chapters in all genres that are available to read or download for FREE without registration. The manga is updated daily to make sure no one will ever miss the latest chapter on their favorite manga. If you like the website, please bookmark it and help us to spread the words. Thank you!',
  Home: 'Home',
  Category: 'Category',
  Search: 'Search',
  'Search manga': 'Search manga',
  Member: 'Member',
  'No results found': 'No results found',
  'View all results': 'View all results',
  'Share Comicaholic': 'Share Comicaholic',
  'to your friends': 'to your friends',
  Trending: 'Trending',
  Recommended: 'Recommended',
  Chapter: 'Chapter',
  Volume: 'Volume',
  Today: 'Today',
  Week: 'Week',
  Month: 'Month',
  'Latest Updates': 'Latest Updates',
  Completed: 'Completed',
  'Show Comments': 'Show Comments',
  'Newest Comments': 'Newest Comments',
  'Top Comments': 'Top Comments',
  'Recommend Comics': 'Recommend Comics',
  'Continue Reading': 'Continue Reading',
  'hours ago': 'hours ago',
  Read: 'Read',
  Type: 'Type',
  Status: 'Status',
  Author: 'Author',
  Views: 'Views',
  voted: 'voted',
  'What do you think about this manga': 'What do you think about this manga',
  Boring: 'Boring',
  Great: 'Great',
  Amazing: 'Amazing',
  'List Chapter': 'List Chapter',
  Genres: 'Genres',
  'Latest Updated': 'Latest Updated',
  'New Release': 'New Release',
  'Most Viewed': 'Most Viewed',
  Completed: 'Completed',
  'Number of Chapters': 'Number of Chapters',
  'You May Also Like': 'You May Also Like',
  "Author's Other Manga": "Author's Other Manga",
  Link: 'Link',
  'You are reading': 'You are reading',
  Comments: 'Comments',
  Settings: 'Settings',
  'Manga Detail': 'Manga Detail',
  'Reading Mode': 'Reading Mode',
  Select: 'Select',
  Vertical: 'Vertical',
  Horizontal: 'Horizontal',
  'Reading Direction': 'Reading Direction',
  Quality: 'Quality',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  'Font Select': 'Font Select',
  'Font Size': 'Font Size',
  'Line Spacing': 'Line Spacing',
  Background: 'Background',
  Black: 'Black',
  White: 'White',
  Close: 'Close',
  'Sort by': 'Sort by',
  'Show spoil': 'Show spoil',
  Reply: 'Reply',
  More: 'More',
  'You must be': 'You must be',
  Login: 'Login',
  'to post a comment': 'to post a comment',
  'View more': 'View more',
  Comment: 'Comment',
  'Prev Chapter': 'Prev Chapter',
  'Next Chapter': 'Next Chapter',
  'Vote now': 'Vote now',
  Loading: 'Loading',
  Filter: 'Filter',
  All: 'All',
  Type: 'Type',
  Comic: 'Comic',
  Novel: 'Novel',
  Status: 'Status',
  Finished: 'Finished',
  Publishing: 'Publishing',
  'On Hiatus': 'On Hiatus',
  Discontinued: 'Discontinued',
  'Not yet published': 'Not yet published',
  'Rating Type': 'Rating Type',
  'G - All Ages': 'G - All Ages',
  'PG - Children': 'PG - Children',
  Score: 'Score',
  Appalling: 'Appalling',
  Horrible: 'Horrible',
  'Very Bad': 'Very Bad',
  Bad: 'Bad',
  Average: 'Average',
  Fine: 'Fine',
  Good: 'Good',
  Great: 'Great',
  Masterpiece: 'Masterpiece',
  Language: 'Language',
  English: 'English',
  Japanese: 'Japanese',
  Vietnamese: 'Vietnamese',
  'Start Date': 'Start Date',
  Year: 'Year',
  Month: 'Month',
  Day: 'Day',
  Sort: 'Sort',
  Default: 'Default',
  'Latest Updated': 'Latest Updated',
  'Name A-Z': 'Name A-Z',
  'Release Date': 'Release Date',
  'Most Viewed': 'Most Viewed',
  'Search results for': 'Search results for',
  'Read Now': 'Read Now',
  Info: 'Info',
  'Locked Chapter': 'Locked Chapter',
  'Unlock chapter to read comic. pls': 'Unlock chapter to read comic. pls',
  Password: 'Password',
  'Enter Password': 'Enter Password',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Unlock: 'Unlock',
  'Buy KNB': 'Buy KNB',
  Tutorial: 'Tutorial',
  'Create Code': 'Create Code',
  'Create Error Report': 'Create Error Report',
  Title: 'Title',
  'Enter title': 'Enter title',
  Details: 'Details',
  'Enter details': 'Enter details',
  Create: 'Create',
  'In process': 'In process',
  Finished: 'Finished',
  Pausing: 'Pausing',
  Canceled: 'Canceled',
  'Translate team': 'Translate team',
  Comics: 'Comics',
  'Terms of service': 'Terms of service',
  DMCA: 'DMCA',
  Contact: 'Contact',
  'Terms and Conditions': 'Terms and Conditions',
  Terms: 'Terms',
  'Modifications and typographical and writing errors':
    'Modifications and typographical and writing errors',
  'Amendment of website terms of use': 'Amendment of website terms of use',
  'Your privacy': 'Your privacy',
  'Management Law': 'Management Law',
  terms_content:
    '<p>By accessing this Website, accessible from <a target="_blank" href="https://comicaholic.com/">https://comicaholic.com/</a>, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law. </p>',
  modifications_typographical_writing_errors_content:
    'Các tài liệu xuất hiện trên Trang web của Hội Mê Truyện có thể bao gồm các lỗi kỹ thuật, đánh máy hoặc nhiếp ảnh. Hội Mê Truyện sẽ không hứa rằng bất kỳ tài liệu nào trong Trang web này là chính xác, đầy đủ hoặc hiện tại. Hội Mê Truyện có thể thay đổi các tài liệu có trên Trang web của mình bất cứ lúc nào mà không cần thông báo. Hội Mê Truyện không đưa ra bất kỳ cam kết nào để cập nhật các tài liệu.',
  amendment_website_terms_use_content:
    'Hội Mê Truyện có thể sửa đổi các Điều khoản sử dụng này cho Trang web của mình bất cứ lúc nào mà không cần thông báo trước. Bằng cách sử dụng Trang web này, bạn đồng ý bị ràng buộc bởi phiên bản hiện tại của các Điều khoản và Điều kiện sử dụng này.',
  privacy_content: 'Vui lòng đọc chính sách bảo mật của chúng tôi.',
  management_law_content:
    'Bất kỳ khiếu nại nào liên quan đến Trang web của Hội Mê Truyện sẽ được điều chỉnh bởi luật của BQ mà không liên quan đến xung đột của các quy định pháp luật.',
  'DMCA takedown request requirements': 'DMCA takedown request requirements',
  'Contact Us': 'Contact Us',
  Updating: 'Updating',
  'Profile Manager': 'Profile Manager',
  'Email address': 'Email address',
  'Display Name': 'Display name',
  'Change password': 'Change password',
  'Current password': 'Current password',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  Save: 'Save',
  'Change Avatar': 'Change Avatar',
  Ascending: 'Ascending',
  Descending: 'Descending',
};
