import { languages } from 'cat-configs/languages';
import EN from 'cat-configs/languages/en';
import VN from 'cat-configs/languages/vi';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const useTrans = () => {
  const { locale, asPath, push, pathname, query } = useRouter();
  const lang = locale === 'en' ? EN : VN;

  const t = useCallback((txt) => {
    if (lang.hasOwnProperty(txt)) {
      return lang[txt];
    } else {
      const lowTxt = txt?.trim().toLowerCase();
      if (lang.hasOwnProperty(lowTxt)) {
        return lang[lowTxt];
      }
    }
    return txt;
  }, []);

  const setLanguage = useCallback((lang) => {
    if (!languages.filter((x) => x.value !== lang) || lang === locale) return;
    push({ pathname, query }, asPath, { locale: lang });
  }, []);

  return [t, setLanguage, languages, locale];
};

export default useTrans;
